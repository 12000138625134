import React, { useState } from 'react';
import styled from 'styled-components';
import { IoIosCall, IoIosMail, IoIosCheckmark } from 'react-icons/io';
import Container from '../components/container';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Headline from '../components/headline';
import { Row, Column } from '../components/grid';

const encode = (data: any) =>
  Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');

const Contacto = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [sent, setSent] = useState(false);

  const send = () => {
    setName('');
    setEmail('');
    setPhone('');
    setMessage('');
    setSent(true);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    fetch('/?no-cache=1', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': 'Contacto',
        // eslint-disable-next-line prettier/prettier
        'Subject': `Solicitud de contacto de ${name} (amvi.org.mx)`,
        // eslint-disable-next-line prettier/prettier
        'Nombre': name,
        'Correo Electrónico': email,
        // eslint-disable-next-line prettier/prettier
        'Teléfono': phone,
        // eslint-disable-next-line prettier/prettier
        'Mensaje': message,
      }),
    })
      .then(() => send())
      .catch((err) => console.error(err));
  };

  return (
    <Layout>
      <SEO
        title="Contacto"
        description="Mejorar la situación fisica, psicologica, emocional, social y economica
          de niños y jovenes que padecen cualquier enfermedad cronico degenerativa
          y de sus familias."
      />
      <Map
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3766.611023746048!2d-99.65428278401225!3d19.255776886985025!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cd897238fb863b%3A0x3838a7fe726a74e1!2sValle%20de%20Cuitzeo%2C%20Delegaci%C3%B3n%20Capultitl%C3%A1n%2C%2050260%20Toluca%20de%20Lerdo%2C%20M%C3%A9x.%2C%20Mexico!5e0!3m2!1sen!2suk!4v1590341271455!5m2!1sen!2suk"
        frameBorder="0"
        aria-hidden="false"
        title="Map"
      />
      <Container>
        <Headline>Contacto</Headline>
        <Row>
          <Column className="two-third">
            {sent && (
              <Success>
                <IoIosCheckmark />
                Gracias por su mensaje. Le responderemos pronto.
              </Success>
            )}
            <Form
              onSubmit={handleSubmit}
              action="#"
              method="post"
              name="Contacto"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
            >
              <label htmlFor="nombre">
                Nombre *
                <input
                  id="nombre"
                  name="Nombre"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  type="text"
                  disabled={sent}
                  required
                />
              </label>
              <label htmlFor="correo">
                Correo Electrónico *
                <input
                  id="correo"
                  name="Correo Electrónico"
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  disabled={sent}
                  required
                />
              </label>
              <label htmlFor="telefono">
                Teléfono
                <input
                  id="telefono"
                  name="Teléfono"
                  type="tel"
                  onChange={(e) => setPhone(e.target.value)}
                  value={phone}
                  disabled={sent}
                />
              </label>
              <label htmlFor="mensaje">
                Mensaje *
                <textarea
                  id="mensaje"
                  name="Mensaje"
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                  disabled={sent}
                  required
                />
              </label>
              <input type="hidden" name="bot-field" id="bot-field" />
              <button type="submit" disabled={sent}>
                Enviar &raquo;
              </button>
              <p style={{ marginTop: '1rem' }}>
                * Los campos marcados son obligatorios
              </p>
              <input
                type="hidden"
                name="subject"
                id="subject"
                value={`Solicitud de contacto de ${name} (amvi.org.mx)`}
              />
            </Form>
          </Column>
          <Column className="third">
            <h2>Puedes contactamos en:</h2>
            <p>
              <IoIosMail />{' '}
              <a href="mailto:hola@amvi.org.mx">hola@amvi.org.uk</a>
              <br />
              <IoIosCall /> <a href="tel:+527223966280">01 722 573 5475</a>
            </p>
            <h3>Dirección</h3>
            <p>
              <strong>AMVI, Amamos Vivir Cada Día A.C.</strong>
              <br />
              6-21-102 Valle de Cuitzeo
              <br />
              Delegación Capultitlán
              <br />
              50260 Toluca de Lerdo
              <br />
              Estado de México
              <br />
              México
            </p>
          </Column>
        </Row>
      </Container>
    </Layout>
  );
};

export default Contacto;

const Map = styled.iframe`
  width: 100%;
  height: 400px;
  border: 0;
  border-bottom: 1px solid #eee;
`;

const Form = styled.form`
  label,
  input,
  textarea {
    display: block;
  }

  input,
  textarea {
    width: 80%;
    margin-bottom: 1rem;
    border-radius: 0.5rem;
    border: 1px solid #eee;
    padding: 0.25rem 0.5rem;
  }

  textarea {
    height: 6em;
  }

  button {
    border: none;
    background: #ff2a58;
    color: #fff;
    min-width: 33%;
    padding: 0.5rem 1rem;
    border-radius: 99px;
    font-weight: bold;
    text-transform: uppercase;
  }
`;

const Success = styled.div`
  color: #236e56;
  background: #84dfc1;
  margin: 1rem 0;
  padding: 0.5rem;
  border: 1px solid #236e56;
  border-radius: 0.5rem;
`;
